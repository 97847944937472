import React, { useState } from "react";
import { Link } from "gatsby";
import dateFormat from "dateformat";
import { useLocation } from "@reach/router";
import { propertyDetailUrl, GetPrice, PropertySearchUrl } from "gatsby-theme-starberry-lomondgroup/src/common/utils/property_utils";
import { ShowProcessedImage } from 'gatsby-theme-starberry-lomondgroup/src/common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json"
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";
import SearchResultsImagePopup from "gatsby-theme-starberry-lomondgroup/src/components/SalesCard/SearchResultsImagePopup"
import { SaveItem } from "@starberry/myaccount-website-utils"
import { HeartIcon } from "gatsby-theme-starberry-lomondgroup/src/common/icons";

const ListView = ({ item, img_type, isGridView, userObjects, collections_property, student_property}) => {

    const location = useLocation();

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }

    var department = item?.department ? item.department : "residential"
 

    var property_id = item.objectID || item.id;
    
    var property_url = propertyDetailUrl(department, item.search_type, item.status, item.slug, property_id);
    //var search_url = PropertySearchUrl(item.department, item.search_type);
    var search_url = location.pathname;

    var img_transforms = imageConfig.property.images.list_view.sizes;
    var thumb_transforms = imageConfig.property.images.thumnail_image.sizes;

    var alt_text = item.display_address + " - " + process.env.GATSBY_SITE_NAME;


    const ImageRenderList = ({ item, image_url, imagename }) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.images_Transforms) {
            processedImages = item?.imagetransforms?.images_Transforms;
        }
        return (
            <ImageModule ImageSrc={image_url} title={item.display_address} altText={item.display_address} imagetransforms={processedImages} renderer="srcSet" imagename={imagename} strapi_id={property_id} />
        )
    }

    var energyRate = item?.energyRate ? item?.energyRate : item?.extra?.energyRate !== "" ? item?.extra?.energyRate : ""

    var prop_images = item?.images &&  item?.images?.length > 0 ? item?.images?.filter(img => img?.url && img.url !== null) : []

    var office_depart = item?.office_crm_id ? item?.office_crm_id : "";


    let pppw = GetPrice(item.price) 

    // if(student_property){
    //     pppw = item.price > 0 && parseInt(item.bedroom) > 0 ? GetPrice(item.price*12/52/item.bedroom) : GetPrice(item.price*12/52/item.bedroom)  
    // }

    return (
        <div className="sales-wrap" id={item.objectID}>
            <div className="sales-img-wrap">
                <div className={`sales-img img-zoom ${item?.images?.length > 1 ? "" : "full_wdth"}`}>
                    {/* <img src={item.images1[0]["570x374"]} alt="img" /> */}
                    <Link to={property_url} state={{ search_url }}>
                        {img_type === "no_property" ?
                            <ImageRenderList item={item} image_url={prop_images[0]}  />
                            :
                            item?.images ? (
                                item?.images.length > 0 ?
                                    <ShowProcessedImage images={item?.images[0]} attr={{ alt: alt_text }} transforms={img_transforms} />
                                :
                                    <ImageRenderList item={item} image_url={item?.images[0]} imagename={"property.images.list_view"} />
                                )
                            :
                                <ImageRenderList item={item} image_url={prop_images[0]}  />
                        }
                    </Link>

                    {item?.officeDepartment === "Prestige" ?
                        <span className="top-text prestige_bg">Hardisty <span className="prestige_clr">Prestige</span></span>

                    : item?.officeDepartment === "Collection" ? (
                            process.env.GATSBY_SITE_NAME === "John Shepherd" ?
                                <span className="top-text collection_bg">John Shepherd <span className="collection_clr">Collection</span></span>
                            :
                                // (collections_property ? 
                                //     ""
                                // :
                                //     <span className="top-text collection_bg"><span className="collection_clr">Collection</span></span>
                                // )
                                ""
                        )

                    : ''
                    }

                    {item?.department === "auction" && <span className="top-text auction_bg">Auction</span>}

                    {(item.status !== "For Sale" && item.status !== "To Let") ? 
                        (process.env.GATSBY_SITE_NAME === "John Shepherd" ?
                            <span className={`top-text ${!collections_property ? item?.officeDepartment : ""}`}>
                                {item.status}
                            </span> 
                        :
                            <span className={`top-text`}>
                                {item.status}
                            </span> 
                        )
                    : ''
                    }
                    

                    {item?.images && item?.images.length > 0 &&
                        <span className="bottom-text" onClick={(e) => openLighboxMobile(e, 0)}>
                            <i className="gallery-icon"></i>
                            <span className="text">{item?.images.length}</span>
                        </span>
                    }
                    {office_depart && (office_depart.includes("LIS") || office_depart.includes("LSO") || office_depart.includes("LSQ")) &&                        
                        <span className="bottom-text dale_eddison_tag">
                            <span className="text">Dale Eddison</span>
                        </span>                        
                    }

                    <SaveItem type="property" pid={property_id} userObjects={userObjects}>
                        <span className="save-item">
                            <HeartIcon /><span> Save</span><span className="saved-text">d</span>
                        </span>
                    </SaveItem>
                </div>

                {/* ======= List View Thumbnail Image ===== */}
                {item?.images && item?.images.length > 1 &&
                    <div className="sm-sales-img-wrap d-none d-xl-block">
                        {item?.images.map((img, i) => {
                            if (i > 0) {
                                return (
                                    <a href="javascript:;" onClick={(e) => openLighboxMobile(e, i)}>

                                        {img_type === "no_property" ?
                                            (i <= 3 &&
                                                <ImageRenderList item={item} image_url={prop_images[i]} />
                                            )
                                            :
                                            <ShowProcessedImage images={item?.images[i]} attr={{ alt: alt_text }} transforms={thumb_transforms} />
                                        }

                                    </a>
                                )
                            }

                        })}
                    </div>
                }

                {isOpen && (item?.images && item?.images?.length != 0) && (
                    <SearchResultsImagePopup
                        propertyId={property_id}
                        propertyImage={item?.images}
                        photoIndex={photoIndex}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        largeSize="720x380"
                        largeSize2="570x374"
                        img_type={img_type}
                    />
                )}
            </div>

            <div className="slide-content">

                <div className="h5"><Link to={property_url} state={{ search_url }}>{item.title}</Link></div>
                <div className="content">
                    <p className="highlight-text">
                        
                        {pppw}  {(item?.price_qualifier && (item?.price_qualifier !== "Offers in the region of" && item?.price_qualifier !== "Offers in excess of")) && <span className="sm-text ">{item.price_qualifier}</span> }
                    
                        {/* {(item?.search_type === "lettings") ? 
                            <span className="sm-text ">{item.price_qualifier}</span> 
                        : ''
                        } 

                        {item?.search_type !== "lettings" && <span className="sm-text">{item.price_qualifier}</span>
                        }                     */}
                    </p>

                </div>

                <div className="d-md-flex title-wrap">
                    <h3><Link to={property_url} state={{ search_url }}>{item.display_address}</Link></h3>
                </div>

                <div className="icon-wrapper">
                    {parseInt(item.bedroom) > 0 ?
                        <div className="icon-wrap">
                            <a href="javascript:;" title="Bedroom">
                                <i className="icon-bed"></i>
                            </a>
                            <span className="count">{item.bedroom}</span>
                        </div>
                        :""
                    }

                    {parseInt(item.bathroom) > 0 ?
                        <div className="icon-wrap">
                            <a href="javascript:;" title="Bathroom">
                                <i className="icon-bath"></i>
                            </a>
                            <span className="count">{item.bathroom}</span>
                        </div>
                        :""
                    }

                    {/* {item.hasParking === true &&
                        <div className="icon-wrap">
                            <a href="javascript:;">
                                <i className="icon-parking"></i>
                            </a>
                            <span className="count">Parking</span>
                        </div>
                    } */}

                    {item.council_tax ?
                        <div className="icon-wrap">
                            <a href="javascript:;" title="Council Tax">
                                <i className="icon-house"></i>
                            </a>
                            <span className="count">{item.council_tax}</span>
                        </div>
                        :""
                    }

                    {energyRate ?
                        <div className="icon-wrap">
                            <a href="javascript:;" title="Energy Rating">
                                <i className="icon-light"></i>
                            </a>
                            <span className="count">{energyRate}</span>
                        </div>
                        :""
                    }
                </div>
                {item.search_type === "lettings" && 
                    (!isGridView ? 
                        (item.available_from && 
                            <div className="available_date">
                                {new Date(item.available_from).valueOf() > new Date().valueOf() 
                                    ? `Available from ${dateFormat(item.available_from, "dd/mm/yyyy")}`
                                    : `Available Now`
                                }
                            </div>
                        )

                    :
                        <div className="available_date">
                            {item.available_from ? 
                                (new Date(item.available_from).valueOf() > new Date().valueOf() 
                                ? `Available from ${dateFormat(item.available_from, "dd/mm/yyyy")}`
                                : `Available Now`
                                )
                                : ''
                            }
                        </div>
                    )
                }

                <p className="d-none d-md-block short_desc">
                    <span>{item?.description?.replace(/<br\s*\/?>/gi, ' ')}</span>
                    {/* {item?.description && <ContentModule Content={item?.description} />} */}

                    <Link to={property_url} className="more-link" state={{ search_url }}>more</Link>
                </p>

            </div>
        </div>
    );
};
export default ListView;
